  
  .card {
    border-radius: none !important;
    border: none !important;
    margin-right: 10px;
  }

  .video-wallet {
    box-shadow: none !important;
    background-color: transparent !important;
  }
  

  #chat-pane {
    background-color: #6699ff;
  }
  
  .chat-col {
    right: -100vw;
    bottom: 0;
    top: 36.5px;
    width: 250px;
    z-index: 1000;
    color: white;
    position: fixed;
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 40px;
    padding-top: 15px;
    min-height: 100vh;
  }
  
  .chat-col.chat-opened {
    right: 0;
    overflow-y: auto;
    overflow-x: hidden;
    transition: all 0.3s ease !important;
    -webkit-transition: all 0.3s ease !important;
    -moz-transition: all 0.3s ease !important;
  }
  
  #chat-messages {
    height: 70vh;
    margin-bottom: 20px;
    overflow-x: hidden;
    overflow-y: auto;
    scrollbar-width: none; /* Firefox */
    -ms-overflow-style: none; /* IE 10+ */
  }
  
  #chat-messages::-webkit-scrollbar {
    width: 0px; /* remove scrollbar space */
    background: transparent;
  }
  
  .chat-box {
    bottom: 30px;
    right: 0;
    background: white !important;
    position: absolute;
    width: 250px;
    border: 0 !important;
    border-top: 0 !important;
    font-size: small;
    border-radius: 0 !important;
  }

  .dx-texteditor-container .dx-texteditor-input-container textarea {
    border-top: 0 !important;
    border-left: 0 !important;
    border-right: 0 !important;
  }
  
  .chat-box::placeholder {
    font-size: small;
    font-weight: lighter;
    font-style: italic;
  }
  
  .chat-box,
  .chat-box:focus {
    resize: none !important;
    box-shadow: none !important;
  }
  
  .chat-row {
    height: 100%;
    overflow-x: scroll;
  }
  
  .remote-video {
    width: 240px;
    height: auto;
    /* margin-left: 8px; */
    max-height: 90vh;
    border-radius: 5px;
    margin-top: 45px !important;
  }
  
  .remote-video:hover + .remote-video-controls,
  .remote-video-controls:hover {
    visibility: visible;
  }
  
  .local-video {
    bottom: 60px;
    left: 8px;
    position: absolute;
    width: 240px;
    border-radius: 5px;
    z-index: 10000;
  }
  
  .mirror-mode {
    -ms-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
  
  .sender-info {
    font-size: smaller;
    margin-top: 5px;
    align-self: flex-end;
  }

  #callOptionsMenu {
    position: absolute;
    bottom: 20px;
    z-index: 10001;
  }

  .button-menu {
    margin-left: 4px;
    margin-bottom: -5px;    
    background: none !important;
    color: white !important;
    width: 80px;
    height: 40px;
    border-color: rgba(0,0,0,0) !important;
    border-radius: 5px !important;
    box-shadow: none !important;
  }

  .button-menu:hover {
    color: black !important;
  }

  .button-menu:hover .fa-desktop {
    color: black !important;
  }

  .button-menu:hover .fa-record-vinyl {
    color: black !important;
  }

  .button-menu:focus {
    background: black;
  }

  .dx-button.dx-state-disabled {
    opacity: 100;
    background: red;
  }
  
  .msg {
    font-weight: 400;
    font-size: 12px;
    color: black;
    background-color: wheat;
    padding: 5px;
  }
  
  .chat-card {
    border-radius: 6px;
  }
  
  .btn-no-effect:focus {
    box-shadow: none;
  }
  
  .very-small {
    font-size: 6px !important;
  }
  
  #close-single-peer-btn {
    position: fixed;
    top: 0;
    text-align: center;
    background: rgba(0, 0, 0, 0.5);
    color: #f1f1f1;
    border-radius: 0%;
    z-index: 100;
  }
  
  .pointer {
    cursor: pointer;
  }
  
  .record-option {
    height: 200px;
    border-radius: 10%;
    border: 1px solid #17a2b8;
    cursor: pointer;
    padding: 10px;
    vertical-align: middle;
  }
  
  @keyframes animatetop {
    from {
      top: -300px;
      opacity: 0;
    }
    to {
      top: 0;
      opacity: 1;
    }
  }
