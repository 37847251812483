.chat-drawer {
  height: calc(100vh - 125px) !important;
}

.chat-drawer .dx-drawer-content {
  overflow: unset;
}

/* sidebar */

.chat-drawer .dx-texteditor-container * {
  border: none;
}

.chat-search {
  display: flex;
  height: 50px;
  padding: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.chat-search-box {
  flex: 1;
  height: 100%;
  border-radius: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

.chat-search-box:after,
.chat-search-box:before {
  display: none;
}

.chat-sidebar {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 250px !important;
  border-right: 1px solid #e1dddd;
  background-color: rgb(221, 221, 221);
}

.add-group-chat-button {
  position: absolute;
  bottom: 15px;
  right: 15px;
}

.chat-dialogs {
  padding: 5px;
  overflow-y: scroll;
  flex: 1;
}

.chat-dialog {
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 7px;
  margin-bottom: 5px;
}

.chat-dialog:hover,
.selected-chat-dialog {
  background-color: rgba(0, 0, 0, 0.08);
}

.chat-dialog-user {
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
}

.chat-dialog-user-title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat-dialog-user-status {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  margin-left: 7px;
  background-color: rgb(33, 212, 78);
}

.chat-dialog-time {
  font-size: 11px;
  font-weight: 400;
  color: #6b6b6b;
  margin-left: auto;
  padding-left: 5px;
  white-space: nowrap;
}

.chat-dialog-message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 27px;
  color: #575757;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.chat-dialog-message-text {
  text-align: left;
  padding-right: 5px;
  margin: 0;
  flex-grow: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.chat-dialog-message-text .sender-name {
  font-weight: 500;
  color: #525151;
}

.chat-dialog-message-text .colon {
  margin-right: 5px;
}

.chat-dialog-messages-count {
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  padding: 2px 7px;
  height: 20px;
  border-radius: 10px;
  background-color: #3379b7;
  color: #fff;
}

/* header */

.chat-body {
  margin-top: -3rem;
  display: flex;
  flex-direction: column;
  height: 100%;
}

/* content */

.chat-header {
  width: 100%;
  height: 50px;
  display: flex;
  background-color: rgb(221, 221, 221);
  padding: 7px 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
}

.chat-user-info {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: flex-start;
}

.chat-user-name {
  font-weight: 500;
  font-size: 14px;
}

.chat-user-status {
  color: #575757;
}

.chat-actions.dx-dropdownbutton {
  height: 30px;
  border-radius: 15px;
}

.chat-actions .dx-state-hover,
.chat-actions .dx-state-focused {
  background-color: transparent !important;
}

.chat-actions .dx-button-content {
  width: 30px;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-actions:hover {
  background-color: rgb(202, 202, 202) !important;
}

.chat-actions-back {
  margin-right: 10px;
  animation: all 0.2 ease-in-out;
}

.chat-search .chat-actions-back {
  margin-right: 5px;
}

.chat-actions-back.rotate-arrow {
  rotate: 180deg;
}

.chat-content {
  width: 100%;
  /* max-height: calc(100% - 50px - 60px); */
  flex: 1;
}

.chat-context-menu {
  z-index: 1;
  position: absolute;
  overflow-x: hidden;
  /* display: flex;
  flex-direction: column; */
  background: #e6e6e6;
  border-radius: 5px;
  padding: 5px 0;
  width: 170px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  /* transition: 0.2s ease-in-out; */
}

.chat-context-menu-item {
  width: 100%;
}

.emoji-list {
  display: flex;
  gap: 7px;
  padding: 0 7px 5px 7px;
  margin-bottom: 5px;
  border-bottom: 1px solid #cecece;
  overflow-x: scroll;
}

.emoji-button {
  width: 28px;
  min-width: 28px;
}

.emoji-button .dx-button-content {
  padding: 5px;
}

.message-to-reply {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 15px;
  border-left: 5px solid #338fdf;
  margin-bottom: 10px;
}

.message-to-reply-content {
  flex: 1;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.message-to-reply-user {
  padding: 0;
  margin: 0;
  color: #338fdf;
  font-weight: 500;
}

.message-to-reply-text {
  display: flex;
  max-width: 100%;
  width: 100%;
  padding-right: 15px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.message-to-reply-text p {
  margin: 0;
  padding: 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.messages-container-wrapper {
  position: relative;
  height: 100%;
}

.messages-container {
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
  padding: 0 15px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.empty-messages-container {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}

.empty-messages-container p {
  margin: 0;
  padding: 7px 15px;
  color: #575757;
  background-color: rgb(221, 221, 221);
  border-radius: 20px;
}

.group-date {
  margin: 15px 0;
  text-align: center;
  font-size: 12px;
  font-weight: 500px;
}

.message {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 3px;
}

.message-emoji-list {
  display: flex;
  padding: 5px 0;
}

.message-emoji-list .message-emoji {
  display: flex;
  align-items: center;
  font-size: 16px;
  background: rgba(0, 0, 0, 0.1);
  padding: 3px 7px;
  border-radius: 16px;
}

.message-emoji-list .message-emoji .message-emoji-count {
  font-size: 12px;
  margin-left: 3px;
}

.message-emoji-list .current-user {
  background-color: rgba(28, 27, 77, 0.2);
}

.message-user-name {
  font-size: 11px;
  font-weight: bold;
  letter-spacing: 1px;
  margin-bottom: 3px;
}

.replied-message {
  width: 100%;
  padding: 0 10px;
  margin-bottom: 2px;
  display: inline-block;
  white-space: break-spaces;
  align-self: flex-start;
  border-left: 2px solid #338fdf;
}

.replied-message-user {
  color: #338fdf;
  font-weight: bold;
}

.own {
  justify-content: flex-end;
}

.own .replied-message {
  border-left: 2px solid #55b0ff;
}

.own .replied-message-user {
  color: #55b0ff;
}

.last-in-group {
  margin-bottom: 10px;
}

.message-content-wrapper {
  position: relative;
  background-color: rgb(221, 221, 221);
  padding: 10px;
  border-radius: 10px;
  max-width: 350px;
  cursor: default;
  padding-right: 50px;
  word-wrap: break-word;
}

.message-content-wrapper p {
  margin: 0;
  padding: 0;
}

.own .message-content-wrapper {
  background-color: #3379b7;
  color: #fff;
  padding-right: 57px;
}

.own .message-content-wrapper a {
  color: #a6d6ff;
}

.last-in-group .message-content-wrapper {
  border-bottom-left-radius: 5px;
}

.own.last-in-group .message-content-wrapper {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 5px;
}

.last-in-group .message-content-wrapper:after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: -30px;
  width: 30px;
  height: 20px;
  display: block;
  box-shadow: 20px 0px 0px 0px rgb(221, 221, 221);
  border-bottom-right-radius: 20px;
}

.own.last-in-group .message-content-wrapper:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: -30px;
  left: unset;
  width: 30px;
  height: 20px;
  display: block;
  box-shadow: -20px 0px 0px 0px #3379b7;
  border-bottom-right-radius: unset;
  border-bottom-left-radius: 20px;
}

.message-info {
  display: flex;
  align-items: baseline;
  position: absolute;
  right: 9px;
  bottom: 3px;
}

.message-time {
  font-size: 11px;
}

.message-delivery-status {
  font-size: 16px;
  font-weight: bold;
}

.message-files {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.file-item {
  box-shadow: none;
  background-color: transparent;
}

.file-item .dx-button-content {
  padding: 0;
}

.file-item .dx-icon {
  width: 25px;
  height: 25px;
  line-height: 25px;
  font-size: 25px;
}

.file-item .dx-button-text {
  color: #0d6efd;
  text-decoration: underline;
}

.message-image-container {
  position: relative;
  max-width: 100px;
  width: 100%;
}

.message-image {
  width: 100%;
  height: auto;
}

.message-image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.2);
}

.message-image-overlay-icon {
  color: white;
  font-size: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.message-image-container:hover .message-image-overlay {
  opacity: 1;
}

/* files popup */

.custom-files-popup-style {
  overflow: unset;
}

.custom-files-popup-style .input-container {
  padding-top: 16px;
}

.files-list {
  max-height: 455px;
  overflow-y: scroll;
}

.files-list-item {
  display: flex;
  margin-bottom: 16px;
}

.files-list-item-info {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  padding-right: 16px;
}

.files-list-item-name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.files-list-item-size {
  color: #6b6b6b;
}

.files-list-item img {
  width: 65px;
  margin-right: 16px;
}

/* footer */

.chat-footer {
  /* display: flex; */
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  padding: 5px 15px 0;
  border-top: 1px solid rgb(221, 221, 221);
}

.input-container {
  display: flex;
  width: 100%;
}

.message-emoji-picker {
  position: absolute;
  top: -315px;
  width: 200px;
  transition: 0.6s ease-in-out;
}

.message-emoji-picker .epr-emoji-category-label {
  display: none !important;
}

.message-input-wrapper {
  position: relative;
  display: flex;
  align-items: flex-end;
  flex: 1;
  min-height: 50px;
  border-radius: 15px;
  border-bottom-right-radius: 5px;
  background-color: rgb(221, 221, 221);
}

.message-input-wrapper:after {
  content: "";
  position: absolute;
  bottom: 0;
  right: -30px;
  width: 30px;
  height: 20px;
  display: block;
  box-shadow: -20px 0px 0px 0px rgb(221, 221, 221);
  border-bottom-left-radius: 20px;
}

.symbol-menu-button {
  display: flex;
  align-items: center;
  height: 50px;
}

.message-imput-text {
  flex: 1;
  background-color: transparent !important;
}

.message-imput-text:after,
.message-imput-text:before {
  display: none;
}

.attaches-button,
.attaches-button:hover {
  background-color: transparent !important;
  border-radius: 0 !important;
}

.chat-footer-send-button {
  border-radius: 50px;
  margin-left: 10px;
  background-color: rgb(221, 221, 221) !important;
}

.chat-footer-send-button:hover {
  background-color: rgb(202, 202, 202) !important;
}

@media (min-width: 1276px) {
  .messages-container,
  .chat-footer {
    width: 1000px;
  }
}
