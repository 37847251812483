.loadpanel-wrapper {
   width: 100%;
   height: 100%;
   position: absolute;
   top: 0;
   border-radius: inherit;
   left: 0;
   z-index: 1500;
   background-color: rgba(255, 255, 255, 0.7);

   .loadpanel-content {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%);
      text-align: center;
      user-select: none;
   }

   .loadpanel-spinner {
      width: 3rem;
      height: 3rem;
      border-width: 0.4rem;
   }
}