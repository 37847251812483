$drop-area-color: #f5f5f5;

.notification-row {
   border-bottom: 1px solid rgba(222, 222, 222, 0.43);
   display: flex;
   align-items: center;
   padding: 4px 0;

   &:last-child {
      border-bottom: none;
   }

   &:hover {
      cursor: pointer;
      background-color: rgba(246, 246, 246, 0.43);
   }
}

.file-upload-wrapper {
   .status-message {
      text-align: center;
      margin: 40px auto;
   }

   .file-upload {
      border-radius: 0;
      box-shadow: 0 0 5px rgb(0 0 0 / 10%);
      background-color: $drop-area-color;
      height: 240px;
      align-items: center;
      position: relative;
      cursor: pointer;
      overflow: hidden;
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 5px 10px;
      max-width: 100%;
      font-size: 1rem;
      text-align: center;
      color: #ccc;
      transition: background-color .3s ease;

      &:hover, &.on-drag {
         background-color: darken($drop-area-color, 5%);
      }
   }

   .file-upload-message {
      position: relative;
      top: 50px;
      -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
   }

   input {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      width: 100%;
      opacity: 0;
      cursor: pointer;
      z-index: 5;
   }

   .file-upload-error-message {
      font-size: 12px;
      text-align: left;
   }
}

.request-file-link {
   padding-left: 0;
}

.uploaded-file-link {
   padding-left: 0;
   padding-right: 0;
}

.modal-dialog {
   .modal-content {
      overflow: hidden;
   }
}

.doc-color-status-new {
   color: $indigo
}

.doc-color-status-review {
   color: $indigo
}

.doc-color-status-rejected {
   color: $indigo
}

.doc-color-status-accepted {
   color: $indigo
}
