.thread-messages-wrapper {
  .message-list {
    pointer-events: all;
    overflow-y: auto;

    &::-webkit-scrollbar-thumb {
      background-color: rgba(191, 191, 191, 0.7);
      box-shadow: 0 0 0 1px transparent;
      border-radius: 2px;
    }

    &::-webkit-scrollbar {
      width: 10px;
      background-color: rgba(249, 249, 253, 0.78);
    }
  }

  .message-date {
    margin-bottom: 8px;
  }

  .message-wrapper {
    box-shadow: 0 0 10px 1px #f7f7f7;
    padding: 1rem;
    border-radius: 1rem;
    display: inline-block;
    white-space: break-spaces;
  }

  .spp-user {
    margin-right: 2rem;
    display: flex;
    flex-direction: column;

    .message-wrapper {
      align-self: flex-start;
      background-color: #ebebeb;
    }
  }

  .client {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    .message-wrapper {
      background-color: #a8ddfb;
    }
  }

  .message-wrapper {
    max-width: 100%;
    word-break: break-word;

    .message-files {
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;

      .file-item {
        margin: 8px 3px 0;
        padding: 0;
        box-shadow: none;
        background-color: transparent;
        font-size: 13px;

        .dx-button-content {
          padding: 0;
        }

        .dx-button-text {
          color: #0d6efd;
          text-decoration: underline;
        }
      }
    }
  }

  .message-text-input {
    line-height: 20px;
    height: 200px;
    width: 100%;
    margin-bottom: 20px;
  }
  .message-file-input {
    margin-bottom: 20px;
  }
  .input-feedback {
    margin-bottom: 20px;
  }
}
