// this is the place for your components / CSS

.footer {
  color: #fff !important;
  font-size: 11px !important;
  background-color: #2647d9 !important;
}

.input-feedback {
  margin-top: 2px;
  color: #2647d9;
}

.marg-top-10 {
  margin-top: 10px;
}