/*
* ===================================================
*    Custom Orion Icons
* ===================================================
*/

.svg-icon {
    width: 32px;
    height: 32px;
    vertical-align: text-bottom;
    stroke: currentColor;
    stroke-width: 3;

    --layer1: currentColor;
    --layer2: currentColor;
    --layer3: currentColor;

    &.svg-icon-light {
        stroke-width: 2;
    }

    &.svg-icon-heavy {
        stroke-width: 4;
    }

}

.svg-icon-sm {
    width: 16px;
    height: 16px;
}

.svg-icon-md {
    width: 24px;
    height: 24px;
}

.svg-icon-lg {
    width: 48px;
    height: 48px;
}

.svg-icon-xl {
    width: 64px;
    height: 64px;
}

.icon {
    display: flex;
    width: 2.2rem;
    height: 2.2rem;
    flex-shrink: 0;
    border-radius: 50%;
    align-items: center;
    justify-content: center;

    &.icon-lg {
        width: 3rem;
        height: 3rem;
    }

    &.icon-xl {
        width: 5rem;
        height: 5rem;
    }

    &.icon-sm {
        width: 1.7rem;
        height: 1.7rem;
        font-size: 0.7rem;
    }
}