.new-notification {
   background-color: #d7ebff;
}

.alert-notification-message {
   position: fixed;
   top: 30%;
   transform: translateX(-50%);
   left: 50%;
   z-index: 1111;
   min-width: 50%;
}

.hidden-empty-list {
   display: none !important;
}

.alert-table-icon-wrap {
   width: 30px;
   text-align: center;
}

