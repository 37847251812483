$breakpoint-lg: 1920px;
$breakpoint-desktop: 1440px;
$breakpoint-md: 991px;
$breakpoint-tablet: 768px;
$breakpoint-phone: 375px;

// text

.main-text {
  font-family: 'HelveticaNeue', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 96px;
  line-height: 115px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;

  @media (max-width: #{$breakpoint-md}) {
    font-size: 48px;
    line-height: 122.3%;
  }

  @media (max-width: #{$breakpoint-tablet}) {
    font-size: 32px;
    line-height: 122.3%;
  }

  &.light {
    background: linear-gradient(234.47deg, rgba(255, 255, 255, 0) 13.46%, rgba(255, 255, 255, 0.61) 32.18%, rgba(255, 255, 255, 0.88) 61.38%, #FFFFFF 85.34%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;

    @media (max-width: #{$breakpoint-tablet}) {
      color: #FFFFFF;
    }
  }

  &.dark {
    color: #171616;
  }
}

.secondary-text {
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  display: flex;
  align-items: flex-end;
  text-align: center;
  justify-content: center;
  /* leading-trim and text-edge are draft CSS properties.
  
  Read more: https://drafts.csswg.org/css-inline-3/#leading-trim
  */
  leading-trim: both;
  text-edge: cap;

  @media (max-width: #{$breakpoint-md}) {
    font-size: 18px;
    line-height: 111.2%;
  }

  &.light {
    color: #FFFFFF;
  }

  &.dark {
    color: #171616;
  }
}


// navbar


.nav-wrapper {
  position: fixed;
  margin-top: 20px;
  z-index: 1;
  width: 100%;

  .nav {
    padding: 17px 30px;
    background-color: #131111;
    border: 1px solid #636363;
    border-radius: 20px;
  
    @media (max-width: #{$breakpoint-lg}) {
      padding: 10px 20px;
    }
  
    .nav-logo {
      padding: 0;
      position: unset;
      transform: none;
  
      img {
        height: 25px;
  
        @media (max-width: #{$breakpoint-lg}) {
          height: 20px;
        }
      }
    }
  
    .nav-link {
      display: flex;
      align-items: center;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
  
      &:hover, &.selected {
        color: #0A96FF;
      }
  
      @media (max-width: #{$breakpoint-md}) {
        justify-content: center;
        font-size: 32px;
        margin-bottom: 18px;
      }
    }
  
    .navbar-toggler {
      padding: 0;
      
      &:focus {
        box-shadow: none;
      }
    }
  }
}



.home-section-wrapper {
  background-image: url("../../../public/img/codioful-formerly-gradienta-bKESVqfxass-unsplash 2.jpg");
  background-repeat: no-repeat;
  background-position: center;
  background-attachment: fixed;
  background-size: cover;
  position: relative;
  padding-top: 100px;

  .home-section {
    padding: 100px 0 150px;

    @media (max-width: #{$breakpoint-md}) {
      padding-bottom: 0;
    }
  
    @media (max-width: #{$breakpoint-tablet}) {
      padding: 15px;
      padding-bottom: 0;
    }

    .home-section-right {
      @media (max-width: #{$breakpoint-md}) {
        margin-top: 70px;
        margin-bottom: 95px;

        display: flex;
        flex-direction: column;
        align-items: center;
      }

      @media (max-width: #{$breakpoint-tablet}) {
        margin-top: 65px;
        margin-bottom: 85px;
      }
    }

    .home-section-left {
      position: relative;

      #dashboard_img {
        position: absolute;
        width: 100%;
        right: 0;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width: #{$breakpoint-md}) {
          position: unset;
          transform: none;
        }
      }

      .description {
        width: 530px;
        position: absolute;
        background: linear-gradient(119.39deg, #0CBAFF -12.29%, #0111FF 83.42%);
        border-radius: 15px;
        bottom: -80px;
        right: 25px;
        padding: 57px 45px;

        @media (max-width: #{$breakpoint-lg}) {
          padding: 35px;
          width: 450px;
        }

        @media (max-width: #{$breakpoint-md}) {
          width: 450px;
          bottom: 160px;
          right: -25px;
        }

        @media (max-width: #{$breakpoint-tablet}) {
          padding: 15px;
          width: 261px;
          top: -40px;
          bottom: unset;
          right: 50%;
          transform: translateX(50%);
        }

        .description-title {
          max-width: 212px;
          color: #FFFFFF;
          font-size: 24px;
          line-height: 29px;
          font-weight: 600;
          margin-bottom: 20px;

          @media (max-width: #{$breakpoint-lg}) {
            font-size: 18px;
            line-height: 20px;
          }

          @media (max-width: #{$breakpoint-tablet}) {
            font-size: 12px;
            line-height: 13px;
            max-width: 140px;
          }
        }

        .description-subtitle {
          max-width: 212px;
          color: #FFFFFF;
          font-size: 14px;
          line-height: 19px;

          @media (max-width: #{$breakpoint-tablet}) {
            font-size: 12px;
            line-height: 15px;
            max-width: 140px;
          }
        }

        #hand_with_phone_img {
          position: absolute;
          width: 306px;
          bottom: 0;
          right: 10px;

          @media (max-width: #{$breakpoint-lg}) {
            width: 215px;
            right: 0;
          }

          @media (max-width: #{$breakpoint-tablet}) {
            width: 100px;
          }
        }
      }
    }

    .home-section-logo {
      width: 215px;

      @media (min-width: #{$breakpoint-lg}) {
        width: 499px;
      }

      @media (min-width: #{$breakpoint-md}) {
        width: 453px;
      }
    }

    .home-secondary-text {
      padding-top: 35px;
      padding-bottom: 50px;
      font-size: 24px;
      line-height: 32px;
      color: #FFFFFF;

      @media (max-width: #{$breakpoint-tablet}) {
        padding-top: 20px;
        font-size: 18px;
        line-height: 20px;
      }
    }
  }
}

.possibilities-section {
  display: flex;
  padding: 25px 0;

  @media (max-width: #{$breakpoint-lg}) {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 30px;
  }

  .possibilities-title {
    @media (max-width: #{$breakpoint-lg}) {
      display: none;
    }

    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    max-width: 320px;
    margin-right: auto;
  }
  
  .possibilities-item {
    display: flex;
    align-items: start;
    max-width: 215px;
    padding: 0 5px;
    font-size: 18px;
    font-weight: 700;
    line-height: 20px;

    @media (max-width: #{$breakpoint-desktop}) {
      max-width: 150px;
      font-size: 12px;
      line-height: 13px;
    }
  
    img {
      margin-right: 15px;
    }
  }
}

.banking-section {
  background-color: #171616;
  padding-top: 100px;
  padding-bottom: 120px;
     
  @media (max-width: #{$breakpoint-lg}) {
    padding-bottom: 80px;
  }

  @media (max-width: #{$breakpoint-md}) {
    padding-bottom: 90px;
  }

  @media (max-width: #{$breakpoint-tablet}) {
    padding-bottom: 40px;
  }

  .banking-section-info {
    margin-top: 192px;
     
    @media (max-width: #{$breakpoint-lg}) {
      margin-top: 179px;
    }

    @media (max-width: #{$breakpoint-md}) {
      margin-top: 85px;
    }

    @media (max-width: #{$breakpoint-tablet}) {
      margin-top: 42px;
    }

    .banking-section-card  {
      position: relative;
      padding: 40px;
      border-radius: 20px;
  
      @media (max-width: #{$breakpoint-lg}) {
        padding: 35px;
      }

      .main-text {
        display: block;
        text-align: start;
        font-weight: 700;
        font-size: 24px;
        line-height: 29px;
      }

      .secondary-text {
        display: block;
        text-align: start;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        margin-top: 25px;
        max-width: 257px;
      }

      .separator {
        width: 45px;
        height: 2px;
        background: #171616;
        margin-bottom: 22px;
      }
    }
  
    .banking-section-main-card {
      height: 361px;
      color: #FFFFFF;
       
      @media (max-width: #{$breakpoint-lg}) {
        height: 275px;
      }
    
      @media (max-width: #{$breakpoint-md}) {
        height: 275px;
      }
    
      @media (max-width: #{$breakpoint-tablet}) {
        height: auto;
        padding-bottom: 270px;
        overflow: hidden;
      }

      img {
        position: absolute;
        bottom: 0;
        right: 28px;
        width: 321px;
       
        @media (max-width: #{$breakpoint-lg}) {
          width: 243px;
        }
    
        @media (max-width: #{$breakpoint-tablet}) {
          bottom: -63px;
        }
      }
    }
  
    .banking-section-secondary-card {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 300px;
      color: #171616;
       
      @media (max-width: #{$breakpoint-lg}) {
        height: 260px;
      }
    
      @media (max-width: #{$breakpoint-md}) {
        height: 260px;
      }
    
      @media (max-width: #{$breakpoint-tablet}) {
        height: auto;
      }
    }

    .features-block {
    
      @media (max-width: #{$breakpoint-lg}) {
        max-width: 566px;
        margin-top: 65px;
      }

      .features-title {
        font-size: 48px;
        line-height: 59px;
        font-weight: 700;
        color: #FFFFFF;
       
        @media (max-width: #{$breakpoint-lg}) {
          font-size: 32px;
          line-height: 39px;
          text-align: center;
        }
      
        @media (max-width: #{$breakpoint-tablet}) {
          font-size: 24px;
          line-height: 29px;
        }
      }

      p {
        margin: 0;
        color: #FFFFFF;
        font-size: 16px;
      }

      .features-subtitle {
        margin-top: 25px;
    
        @media (max-width: #{$breakpoint-lg}) {
          text-align: center;
        }
      }

      .features-list {
        position: relative;
        margin-top: 50px;

        img {
          position: absolute;
          top: 0;
          left: 3px;
        }

        .features-item {
          display: flex;
          gap: 35px;
          margin-bottom: 40px;

          .list-dot {
            display: block;
            width: 11px;
            height: 11px;
            min-width: 11px;
            background-color: #FFFFFF;
            border-radius: 6px;
          }

          .list-text {
            font-size: 18px;
            font-weight: 600;
      
            @media (max-width: #{$breakpoint-tablet}) {
              font-size: 16px;
              line-height: 18px;
            }
          }
        }
      }
    }
  }

  .banking-cards {
    display: flex;
    justify-content: center;

    img {
      width: 1095px;
      margin-top: 80px;
      
      @media (max-width: #{$breakpoint-tablet}) {
        width: 586px;
        margin-top: 19px;
      }
    }
  }
}

.benefit-cards-section {
  margin-top: 93px;

  @media (max-width: #{$breakpoint-lg}) {
    margin-top: 115px;
  }

  @media (max-width: #{$breakpoint-md}) {
    margin-top: 50px;
  }

  @media (max-width: #{$breakpoint-tablet}) {
    margin-top: 40px;
  }
}

.cryptocurrency-alt {
  @media (max-width: #{$breakpoint-tablet}) {
    margin-bottom: 160px;
  }
}

.etf-card {
  .refresh {
    position: absolute;
    top: 0;
    right: 0;

    @media (max-width: #{$breakpoint-tablet}) {
      width: 200px;
    }
  }

  .refresh-bage {
    position: absolute;
    top: 20px;
    right: 120px;

    @media (max-width: #{$breakpoint-tablet}) {
      width: 100px;
      top: 10px;
      right: 70px;
    }
  }
}

.precious-metals {
  @media (max-width: #{$breakpoint-tablet}) {
    padding-bottom: 270px !important;
  }

  .metals-phone {
    position: absolute;
    bottom: 0;
    right: -40px;

    @media (max-width: #{$breakpoint-lg}) {
      bottom: -30px;
      right: -80px;
    }

    @media (max-width: #{$breakpoint-md}) {
      bottom: 0;
      right: -40px;
    }

    @media (max-width: #{$breakpoint-tablet}) {
      right: 30px;
    }
  }

  .metals-card {
    position: absolute;
    bottom: 0;
    right: 0;

    @media (max-width: #{$breakpoint-lg}) {
      bottom: -80px;
      right: -80px;
    }

    @media (max-width: #{$breakpoint-md}) {
      bottom: 0;
      right: 0;
    }

    @media (max-width: #{$breakpoint-tablet}) {
      left: 0;
      bottom: -35px;
    }
  }
}

.stocks-block {
  @media (max-width: #{$breakpoint-tablet}) {
    padding-top: 300px !important;
  }

  .stocks-phone, .stocks-cpi, .stocks-tsla {
    position: absolute;
  }

  .stocks-phone {
    right: 200px;
    top: 0;

    @media (max-width: #{$breakpoint-lg}) {
      width: 545px;
      right: 50px;
      top: -20px;
    }

    @media (max-width: #{$breakpoint-md}) {
      width: 350px; 
      right: 20px;
      top: 10px;
    }

    @media (max-width: #{$breakpoint-tablet}) {
      width: 350px;
      left: 50%;
      top: -20px;
      transform: translateX(-50%);
    }
  }

  .stocks-cpi {
    width: 100px;
    height: 100px;
    top: 40px;
    right: 750px;

    @media (max-width: #{$breakpoint-lg}) {
      top: 40px;
      right: 600px;
    }

    @media (max-width: #{$breakpoint-md}) {
      width: 67px;
      height: 67px;
      top: 30px;
      right: 400px;
    }

    @media (max-width: #{$breakpoint-tablet}) {
      left: -30px;
      top: 30px;
    }
  }

  .stocks-tsla {
    width: 151px;
    height: 151px;
    right: 40px;
    bottom: 40px;

    @media (max-width: #{$breakpoint-lg}) {
      bottom: 30px;
      right: 50px;
    }

    @media (max-width: #{$breakpoint-md}) {
      width: 116px;
      height: 116px;
      bottom: 20;
      right: 30;
    }

    @media (max-width: #{$breakpoint-tablet}) {
      right: 15px;
      bottom: 230px;
    }
  }
}

.custom-card {
  position: relative;
  border-radius: 25px;
  padding: 50px;
  height: 445px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  @media (max-width: #{$breakpoint-desktop}) {
    padding: 45px;
    height: 400px;
  }

  @media (max-width: #{$breakpoint-md}) {
    padding: 35px;
    // height: 277px;
  }

  @media (max-width: #{$breakpoint-tablet}) {
    padding: 25px;
    height: auto;
  }

  .main-card-text {
    font-family: 'HelveticaNeue', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 48px;
    line-height: 122.3%;
    word-wrap: normal;

    @media (max-width: #{$breakpoint-lg}) {
      font-size: 32px;
      line-height: 122.3%;
    }
  }
  
  .secondary-card-text {
    font-family: 'Open Sans', 'Arial', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 111.2%;
    
    leading-trim: both;
    text-edge: cap;
    display: flex;
    align-items: center;

    @media (max-width: #{$breakpoint-lg}) {
      font-size: 16px;
      line-height: 125%;
    }
  }

  &.custom-card-main {
    background-color: #FFFFFF;

    .main-card-text, .secondary-card-text {
      color: #171616;
    }
  }

  &.custom-card-secondary {
    background-color: #0A96FF;

    .main-card-text, .secondary-card-text {
      color: #FEFEFF;
    }
  }
}

.aiclub-graphic {
  background-image: url("../../../public/img/aiclub-background.png");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  position: relative;

  height: 100%;
  width: 100%;

  @media (max-width: #{$breakpoint-md}) {
    height: 400px
  }

  img {
    position: absolute;
    top: 0;
    right: 0;
    height: 80%;

    @media (max-width: #{$breakpoint-desktop}) {
      height: 400px
    }
  }
}

.aiclub-info {
  padding: 251px 0;

  @media (max-width: #{$breakpoint-desktop}) {
    padding: 267px 0;
  }

  @media (max-width: #{$breakpoint-md}) {
    padding: 70px 0;
  }

  @media (max-width: #{$breakpoint-tablet}) {
    padding: 35px 0;
  }

  .aiclub-title {
    font-size: 96px;
    line-height: 115px;
    font-weight: 700px;
    color: #171616;

    @media (max-width: #{$breakpoint-desktop}) {
      font-size: 48px;
      line-height: 59px;
    }
  
    @media (max-width: #{$breakpoint-md}) {
      font-size: 32px;
      line-height: 39px;
    }
  }

  .aiclub-subtitle {
    margin: 0;
    margin-top: 35px;
    font-size: 24px;
    line-height: 33px;
    font-weight: 400px;
    color: #171616;

    @media (max-width: #{$breakpoint-desktop}) {
      font-size: 18px;
      line-height: 20px;
    }
  }
}

.whyus-section {
  background-color: #FBF4F4;
  padding: 110px 0 50px;

  .whyus-section-title {
    color: #171616;
    font-size: 96px;
    font-weight: 700;
    line-height: 115px;

    @media (max-width: #{$breakpoint-md}) {
      text-align: center;
      font-size: 48px;
      line-height: 59px;
    }

    @media (max-width: #{$breakpoint-tablet}) {
      font-size: 32px;
      line-height: 39px;
    }
  }

  .whyus-section-subtitle {
    color: #171616;
    font-size: 18px;
    line-height: 20px;

    @media (max-width: #{$breakpoint-md}) {
      text-align: center;
    }
  }

  .whyus-item {
    .whyus-item-title {
      display: flex;
      align-items: center;
      font-size: 48px;
      font-weight: 700;
      line-height: 59px;
      color: #0A96FF;

      @media (max-width: #{$breakpoint-md}) {
        font-size: 32px;
        line-height: 39px;
      }

      img {
        margin-right: 20px;
      }

      p {
        max-width: 255px;
      }
    }

    .whyus-item-subtitle {
      font-size: 18px;
      line-height: 20px;
      margin-top: 35px;
    }
  }

  .compas {
    top: 75px;
    right: 0;
  }
}

.experts-container-wrapper {
  padding-top: 140px;
  background-color: #171616;
  padding-bottom: 100px;

  @media (max-width: #{$breakpoint-desktop}) {
    padding-top: 87px;
  }

  @media (max-width: #{$breakpoint-md}) {
    padding-top: 87px;
  }

  @media (max-width: #{$breakpoint-tablet}) {
    padding-top: 81px;
  }

  .experts-container {
    #face-1 {
      top: 116px;
      left: 275px; 
  
      @media (max-width: #{$breakpoint-desktop}) {
        top: 75px;
        left: 103px;
        height: 77px;
        width : 77px;
      }
  
      @media (max-width: #{$breakpoint-md}) {
        top: 61px;
        left: 58px;
        height: 54px;
        width : 54px;
      }
  
      @media (max-width: #{$breakpoint-tablet}) {
        top: 26px;
        left: -38px;
      }
    }
  
    #face-2 {
      top: 140px;
      right: 311px;
      height: 58px;
      width : 58px;
  
      @media (max-width: #{$breakpoint-desktop}) {
        top: 54px;
        right: 108px;
        height: 46px;
        width : 46px;
      }
  
      @media (max-width: #{$breakpoint-md}) {
        top: 37px;
        right: 64px;
        height: 46px;
        width : 46px;
      }
  
      @media (max-width: #{$breakpoint-tablet}) {
        top: 15px;
        right: 28px;
        height: 46px;
        width : 46px;
      }
    }
  
    #face-3 {
      top: 460px;
      right: 418px;
      height: 70px;
      width : 70px;
  
      @media (max-width: #{$breakpoint-desktop}) {
        top: 367px;
        right: 242px;
        height: 49px;
        width : 49px;
      }
  
      @media (max-width: #{$breakpoint-md}) {
        top: 339px;
        right: 117px;
      }
  
      @media (max-width: #{$breakpoint-tablet}) {
        top: 353px;
        right: -25px;
      }
    }
  }
}




// cookies popup

.cookies-popup-wrapper {
  position: fixed;
  margin-bottom: 20px;
  z-index: 1;
  width: 100%;
  
  @media (max-width: #{$breakpoint-tablet}) {
    margin-bottom: 0;
  }

  .cookies-popup {
    padding: 10px;
    padding-right: 17px;
    background-color: #FFFFFF;
    border-radius: 63px;
  
    @media (max-width: #{$breakpoint-md}) {
      border-radius: 20px;
    }
  
    @media (max-width: #{$breakpoint-tablet}) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
}


// footer

.landing-footer {
  background-color: #171616;
  padding: 50px 0;

  @media (min-width: #{$breakpoint-md}) {
    padding-bottom: 140px;
  }

  @media (min-width: #{$breakpoint-lg}) {
    padding-bottom: 300px;
  }

  .footer-navigation {
    display: flex;
    gap: 15px;
    margin-top: 25px;
  
    .nav-link {
      display: flex;
      align-items: center;
      color: #FFFFFF;
      font-size: 18px;
      font-weight: 400;
      line-height: 21px;
  
      &:hover, &.selected {
        color: #0A96FF;
      }
    }
  }

  .footer-logo {
    width: 130px;
  }

  .fmt {
    margin-top: 113px;

    @media (max-width: #{$breakpoint-md}) {
      margin-top: 40px;
    }
  }
}

.policy-content-container {
  .nav {
    overflow-x: scroll;
    flex-wrap: nowrap;
  }

  .nav-item {
    margin-right: 3px;
    margin-left: 12px;
  }

  .nav-link {
    display: flex;
    justify-content: center;
    min-width: 200px;
    background-color: #FFFFFF;
    font-size: 18px;
    line-height: 21px;
    color: #171616;
    border-radius: 73px;
  }

  .nav-link.active {
    background-color: #0A96FF;
  }

  .policy-title {
    color: #FFFFFF;
    font-size: 48px;
    line-height: 59px;
    font-weight: 700;
  }

  .policy-subtitle {
    color: #FFFFFF;
    font-size: 24px;
    line-height: 29px;
    margin-top: 40px;
    font-weight: 700;
  }

  .policy-text {
    max-width: 890px;
    color: #FFFFFF;
    font-size: 16px;
    line-height: 20px;
    margin-top: 25px;
  }
}