#callDraggable {
    position: fixed;
    width: 256px;
    height: 500px;
    top: 10px;
    left: 0px;
    z-index: 10000;
  }
  
  #callOverlay {
    position: fixed;
    width: 256px;
    min-width: 256px;
    height: 500px;
    min-height: 500px;
    background: rgba(0,0,0,0.5);
    top: 10px;
    border-radius: 5px;
    left: 1150px;
    z-index: 10000;
  }
  
  #endCall {
    position: absolute;
    right: 0;
    top: 5px;
    background: none !important;
    color: white !important;
    width: 60px;
    height: 40px;
    border-color: rgba(0,0,0,0) !important;
    border-radius: 5px !important;
    box-shadow: none !important;
  }
  
  #endCall:hover {
    color: black !important;
  }